<template>
  <div>
    <div class="d-flex login-container">
      <div class="login-form d-flex align-items-center position-relative col-sm-12 col-lg-7">
        <div class="single-column-form-wrap mx-auto w-100 px-5 d-flex flex-column">
          <div class="mx-auto mb-4">
            <img
              :src="genuityLogoSrc()"
              alt="genuity-logo"
              height="120"
              width="273"
            >
          </div>
          <div v-if="resetCodeStep">
            <div class="form-group mb-5">
              <label for="email">Email</label>
              <input
                id="email"
                ref="emailInput"
                v-model="forgotEmail"
                v-validate="'required|email'"
                type="email"
                class="form-control"
                name="email"
                placeholder="yourname@company.com"
                :class="{'is-invalid': errors.has('email')}"
              >
              <span
                v-show="errors.has('email')"
                class="form-text text-danger small"
              >
                {{ errors.first('email') }}
              </span>
            </div>
            <div
              v-if="runCaptcha"
              class="text-center"
            >
              <vue-recaptcha
                ref="recaptcha"
                size="invisible"
                :sitekey="recaptchaSiteKey"
                :load-recaptcha-script="true"
                @verify="verifyCaptcha"
                @expired="captchaExpired"
                @error="captchaError"
              >
                <button
                  class="btn btn-primary w-50"
                  :disabled="isSubmitting"
                >
                  Submit
                </button>
              </vue-recaptcha>
            </div>
            <div
              v-else 
              class="text-center"
            >
              <button
                class="btn btn-primary w-50"
                :disabled="isSubmitting"
                @click="sendForgotEmail"
              >
                Submit
              </button>
            </div>
            <div class="text-secondary not-as-small text-center mt-4">
              <a
                href=""
                class="btn btn-link btn-sm d-inline-block align-middle"
                @click.prevent="$router.push('/users/sign_in')"
              >Back to login</a>
            </div>
          </div>

          <reset-password 
            v-else
            :forgot-email="forgotEmail"
            @forgot-email-step="forgotEmailStep"
          />
        </div>
      </div>
      <quotes />
    </div>
  </div>
</template>

<script>
  import http from 'common/http';
  import VueRecaptcha from 'vue-recaptcha';
  import { mapMutations, mapGetters } from 'vuex';
  import ResetPassword from './reset_password.vue'
  import Quotes from './quotes'

  export default {
    $_veeValidate: {
      validator: "new"
    },
    components: {
      ResetPassword,
      VueRecaptcha,
      Quotes
    },
    data() {
      return {
        forgotEmail: '',
        resetCodeStep: true,
        isSubmitting: false,
      };
    },
    computed: {
      ...mapGetters('GlobalStore', [
        'isRobot',
        'runCaptcha',
        'recaptchaSiteKey',
      ]),
    },
    methods: {
      ...mapMutations('GlobalStore', ['setRobotStatus']),
      verifyCaptcha(res) {
        http
          .post('/verify_recaptcha.json', { response: res })
          .then(res => {
            if (res.data.success) {
              this.setRobotStatus(false);
              this.sendForgotEmail();
            } else {
              this.isSubmitting = false;
              this.emitError("Sorry silly robot, this app is for humans! (If you're a human and believe you're seeing this in error, please contact our <a href='mailto:support@gogenuity.com' class='text-white text-underline'>support team</a>.");
              this.resetCaptcha();
            }
          })
          .catch(err => {
            this.isSubmitting = false;
            this.resetCaptcha();
          });
      },
      captchaExpired(res) {
        this.resetCaptcha();
        this.setRobotStatus(true);
      },
      captchaError(error) {
        if (window.Bugsnag && Bugsnag.notify) {
          Bugsnag.notify(error);
        }
        this.emitError('Sorry, we are having some trouble verifying with reCAPTCHA. Please <a href="javascript:window.location.reload(true)" class="text-underline text-white">refresh</a> and try again, or reach out to our <a href="mailto:support@gogenuity.com" class="text-underline text-white">support team</a>.');
      },
      resetCaptcha() {
        if (this.runCaptcha && this.$refs.recaptcha) {
          this.$refs.recaptcha.reset();
        }
      },
      sendForgotEmail() {
        this.$validator.validateAll().then(result => {
          if (result) {
            this.isSubmitting = true;
            http
              .post('/send_reset_password_code', { email: this.forgotEmail })
              .then(res => {
                this.resetCodeStep = false
                this.emitSuccess(`We have sent an email to ${this.forgotEmail} with your password reset code` )
              })
              .catch(error => {
                this.forgotEmail = ''
                this.emitError(error.response.data.message);
              })
              .finally(() => {
                this.resetCaptcha();
                this.isSubmitting = false;
              })
          } else {
            this.isSubmitting = false;
            this.resetCaptcha();
            this.emitError("Please correct errors in the form before submitting");
          }
        })
      },
      forgotEmailStep() {
        this.resetCodeStep = true;
      },
       genuityLogoSrc() {
        const imgName = this.$siteTheme === "dark" ? "logo-white.svg" : "logo.svg";
        return require(`images/${imgName}`);
      },
    }
    
  };
</script>

<style lang="scss" scoped>
.login-container {
  min-height: 100vh;
}
.btn-primary {
  min-height: 50px;
}
.already-registered {
  position: absolute;
  right: 1.5rem;
  top: 1.5rem;
}
</style>
