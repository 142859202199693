import { render, staticRenderFns } from "./forgot_password.vue?vue&type=template&id=71bc8006&scoped=true"
import script from "./forgot_password.vue?vue&type=script&lang=js"
export * from "./forgot_password.vue?vue&type=script&lang=js"
import style0 from "./forgot_password.vue?vue&type=style&index=0&id=71bc8006&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "71bc8006",
  null
  
)

export default component.exports